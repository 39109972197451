import { apiGet, apiPost, filepost } from './index'

export async function listDocTemplate(data) {
	return await apiPost("/api/DocTemplate/list", data);
}

export async function fullListDocTemplate(data) {
	return await apiPost("/api/DocTemplate/fulllist", data);
}

export function getDocTemplate(data) {
	let url = "/api/DocTemplate/read/" + data;
	return apiGet(url, { params: { timestamp: Math.random() } });
}

export async function postDocTemplate(data) {
	return await filepost("/api/DocTemplate/edit", data);
}

export async function addDocTemplate(data) {
	return await filepost("/api/DocTemplate/add", data);
}

export function deleteDocTemplate(data) {
	return apiGet("/api/DocTemplate/delete/" + data);
}

export async function batchCreate(data) {
	return await apiGet("/api/DocTemplate/batch/" + data);
}

export async function downloadTemplate(data) {
	return await apiGet("/api/DocTemplate/download/" + data, {
		responseType: 'blob',
	});
}